import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormControl, MenuItem, Select } from '@mui/material'

const getSelectWithHeader = (options) => {
  const categorySet = new Set()
  const newOptions = []

  options.sort((a) => a.type?.localeCompare(a.type))
  options.map((option) => {
    if (option.type) {
      if (!categorySet.has(option.type)) {
        categorySet.add(option.type)
        newOptions.push({ value: '', name: option.type })
      }
    }
    newOptions.push({ value: option.value, name: option.name })
  })
  return newOptions
}

const Solid = ({ options, changeMenuState, currentOption }) => {
  const [selectData, setSelectData] = useState([])
  const [optionValue, setOptionValue] = useState(options[0].value)
  const handleChange = (event) => {
    setOptionValue(event.target.value)
    changeMenuState(event, event.target.value)
  }
  useEffect(() => {
    setSelectData(getSelectWithHeader(options))
  }, [options])

  useEffect(() => {
    setOptionValue(currentOption)
  }, [currentOption])

  return (
    <FormControl size="small" fullWidth>
      <Select
        value={optionValue}
        label=""
        onChange={handleChange}
        inputProps={{
          sx: {
            backgroundColor: 'white',
            color: '#21838D',
            fontWeight: 700
          }
        }}
      >
        {selectData.map((option, index) => {
          const isCategory = option.value === ''

          return (
            <MenuItem key={index} value={option.value} disabled={isCategory} dense>
              <span className={`capitalize font-bold ${isCategory ? 'text-gray-600 text-xs' : ''}`}>{option.name}</span>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
Solid.propTypes = {
  options: PropTypes.array,
  changeMenuState: PropTypes.func,
  currentOption: [PropTypes.number, PropTypes.string]
}

export default Solid
